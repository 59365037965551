.move_log{
  margin-left: 1em;
  margin-right: 1em;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
}

.table{
  display: block;
  height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
}