@keyframes slide-up{
  0% {
      transform:  translate(-15%,-40%);
  }
  100% {
      transform:  translate(-50%,-50%);
  }
}

.messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

.new-message-input-field {
    font-size: 20px;
    padding: 8px 8px;
    resize: none;
    border: 3px solid black;
    width: 100%;
}

.message-item {
    width: 60%;
    margin: 8px;
    padding: 6px 6px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}

.my-message {
    background-color: #31a24c;
    margin-left: auto;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
}


.messages-container, .new-message-input-field, .send-message-button {
    border-color: black;
}

.text-send{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.send-message-button {
    margin-left: 0px;
    margin-right: 0px;
}

.open-button {
    background-color: white;
    color:black;
    // padding: 20px 20px;
    border-style: solid;
    cursor: pointer;
    // opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    // width: 280px;
    border-radius: 50%;
    z-index: 5;
  }


  @media (min-width: 960px) {
    .chat-room-container {
      width: 60%;
    }
  }
 