.home-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7.5vh 0 0 0;

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    // top: 50%;
    // left: 50%;

    /* bring your own prefixes */
    // transform: translate(-50%, -50%);
    #ranked, #casual, #ai {
      display:flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 17.5vh;
      margin: 0 0 .75em 0;
      font-size: 200%;
      .btn-icon{
        height: 12vh;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .home-container{
    padding: 12.5% 0 0 0;
    .menu {
      width:80%;
      // #ranked, #casual, #ai {
      //   width: 100%;
      // }
    }
  }
}
