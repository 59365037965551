.modal-login {

  position: relative;
  background-color:white;
  // width: 40%;
  width: 80%;
  // height: 55%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  border-style: solid;
  font-size: 20px;

  h1 {
    font-size: 50px;
  }
  
  .modal-login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-login-form {

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 60%;
      cursor: pointer;

    }
  }

  .to-register {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }

  .submit {
    margin-top: 10px;
    border-radius: 10px;
  }

}

@media (min-width: 560px) {
  .modal-login {
    width: 60%;
    height: 55%;
    font-size: 20px;

  }
}

@media (min-width: 960px) {
  .modal-login {
    width: 30%;
    height: 55%;
    font-size: 25px;
  }
}