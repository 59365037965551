main {
  background:#F0F0D8;
}
.user-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    // margin-top: 60px;

  .user-icon {
    height: 150px;
    width: 150px;
    // border: 1px solid black;
    // padding: 10px;
    border-radius: 50%;
    margin-top: 60px;
    position: relative;

    #avatar {
      cursor: pointer;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      // margin-right: 15px;
      height: 30px;
      width: 30px;
      background-color: white;

      img {
        height: 20px;
        width: 20px;
      }
    } 
  }

  .user-info {
    font-size: 32px;
    display: inline-block;


    .rank-img{
      height: 40px;
      display: inline-block;
      position: absolute;
      margin-left: -45px;
    }
  }

  #edit {
    margin-top: 10px;
  }

}

