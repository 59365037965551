.profile-btn-group {
  // margin: px 5% 0px 5%;
  margin-top: 20px;
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: space-between;

  button {
    width: 33.3%;
    // margin: 0 2em;
    display: flex;
    justify-content: center;
  }

  .bttn-primary {
    color: black;
  }
}

@media (min-width: 960px) {
  .profile-btn-group{
    width:70%;
  }
}
@media (min-width: 1200px) {
  .profile-btn-group{
    width:60%;
  }
}