.modal-bg1, .modal-bg2, .modal-bg3 {
  z-index: 1;
  position:fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  background-color:rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items:center;
  visibility:hidden;
  opacity:0;
  transition:visibility 0s, opacity 0.5s;
}

.modal-bg4 {
  z-index: 1;
  position:fixed;
  width:100%;
  height:100vh;
  top:0;
  left:0;
  background-color:rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility:hidden;
  opacity:0;
  transition:visibility 0s, opacity 0.5s;
}

.bg-active{
  visibility:visible;
  opacity:1;
}

.modal-close{
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  top: 10px;
  right: 0px;
  height: 40px;
  width: 20px;
  font-weight:bold;
  cursor: pointer;
  border:none
}