.modal-settings {
  position: relative;
  background-color: white;
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  border-style: solid;

  .modal-settings-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .modal-settings-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 60%;
      font-size: 20px;
    }

    #edit {
      border-radius: 10px;
    }
  }

  .modal-settings-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 28px;
    font-weight: normal;
  }

  .submit {
    font-size: 20px;
    margin-top: 10px;
    border-radius: 10px;
  }

}


@media (min-width: 560px) {
  .modal-settings {
    width: 60%;
    height: 55%;
  }
}

@media (min-width: 960px) {
  .modal-settings {
    width: 30%;
    height: 55%;
  }
}