.stats-box {
  display: flex;
  justify-content: center;
  width:100%;
  margin-top: 10px;

  .stats {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  table {
    display: table;
    border:none;
    border-collapse: collapse;
    width: 100%;

    tbody {
      width: 100%;

      tr {
        display: flex;
        justify-content: space-evenly;
      
        td {
          display: flex;
          justify-content: center;
          width: 100%;
          height:100%;
        }

        th {
          display: flex;
          justify-content: center;
          width: 100%;
          height:100%;
        }
      }
    }
  }
}

// @media (min-width: 960px) {
//   .stats-box {
//     .stats {
//       width: 70%;
//     }
//   }
// }

// @media (min-width: 1200px) {
//   .stats-box {
//     .stats {
//       width: 60%;
//     }
//   }
// }