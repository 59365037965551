.match {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border:1px solid black;
  margin-top: 5px;
  overflow: auto;
  .profile-img {
    width:50px;
    height:50px;
    border-radius: 50%;
  }
  .rank-img{
    height: 30px;
  }
}