.modal-avatar {
  position: relative;
  background-color:white;
  // width: 40%;
  width: 80%;
  // height: 55%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 25px;
  border-style: solid;
  
  .modal-avatar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;

    #avatar-preview {
      border: black;
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
  }
  .url-error {
    // margin-top:10px;
    margin-bottom: -10px;
    width: 150px;
  }

  .submit {
    margin-top: 20px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    // margin-bottom: 10px;

  }

  .modal-avatar-form {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 560px) {
  .modal-avatar {
    width: 60%;
    height: 55%;
  }
}

@media (min-width: 960px) {
  .modal-avatar {
    width: 40%;
    height: 55%;
    .modal-avatar-content {
      width: 300px;
      height: 300px;

      #avatar-preview {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .modal-avatar {
    width: 30%;
    height: 55%;
    .modal-avatar-content {
      width: 350px;
      height: 350px;

      #avatar-preview {
        width: 350px;
        height: 350px;
      }
    }
  }
}