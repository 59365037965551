.modal-register {
  position: relative;
  background-color: white;
  // width: 40%;
  width: 80%;
  // height: 55%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  border-style: solid;

  .modal-register-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-register-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 60%;

      #register-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        // margin: auto;
        margin-top: 10px;
        border-radius: 10px;
      }
    }
  }

}

@media (min-width: 560px) {
  .modal-register {
    width: 60%;
    height: 55%;
  }
}

@media (min-width: 960px) {
  .modal-register {
    width: 30%;
    height: 55%;
  }
}